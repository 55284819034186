/*
@File: Ledu HTML Template

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************
** - Default CSS Style
** - Top Header Area CSS Style
** - Navbar Area CSS Style
** - Main Banner Area CSS Style
** - Inner Banner Area CSS Style
** - Counter Area CSS Style
** - Featured Area CSS Style
** - Courses Area CSS Style
** - Cart Area CSS Style
** - Checkout Area CSS Style
** - Play Area CSS Style
** - FAQ Area CSS Style
** - Instructors Area CSS Style
** - Testimonials Area CSS Style
** - Enrolled Area CSS Style
** - Event Area CSS Style
** - Contact Area CSS Style
** - Blog Area CSS Style
** - Blog Details Area CSS Style
** - Register Area CSS Style
** - Footer Area CSS Style
** - Other Area CSS Style


*******************************************
/*

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap'); */

:root {
  --bodyFonts: "Jost", sans-serif;
  --mainColor: #08a9e6;
  --redColor: #ec272f;
  --titleColor: #21225f;
  --bodyColor: #555555;
  --whiteColor: #ffffff;
  --fontSize: 16px;
  --transition: 0.5s;
}

body {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  color: var(--bodyColor);
  font-weight: 400;
  font-size: var(--fontSize);
  font-family: var(--bodyFonts);
}

p {
  color: var(--bodyColor);
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

button {
  outline: 0;
  border: none;
}

button:focus {
  outline: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--titleColor);
  line-height: 1.4;
  font-family: just-sans;
}

h3 {
  font-size: 22px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

img {
  max-width: 100%;
}

.pt-150 {
  padding-top: 150px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-0 img {
  border-radius: 0 !important;
}

.box-shadow {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.default-btn {
  padding: 15px 35px;
  text-align: center;
  color: var(--whiteColor);
  font-size: var(--fontSize);
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  background: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
}

.default-btn i {
  position: relative;
  top: 3px;
  margin-left: 10px;
}

.default-btn:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 550px;
  height: 550px;
  margin: auto;
  background: var(--titleColor);
  border-radius: 50%;
  z-index: -1;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: translateX(-50%) translateY(-5%) scale(0.4);
  transform: translateX(-50%) translateY(-5%) scale(0.4);
  -webkit-transition: -webkit-transform 0.9s;
  transition: -webkit-transform 0.9s;
  transition: transform 0.9s;
  transition: transform 0.9s, -webkit-transform 0.9s;
}

.default-btn.two {
  background: var(--redColor);
  color: var(--whiteColor);
}

.default-btn.two::before {
  background-color: var(--mainColor);
}

.default-btn:hover {
  color: var(--whiteColor) !important;
}

.default-btn:hover:before {
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateX(-45%) translateY(0) scale(1);
  transform: translateX(-45%) translateY(0) scale(1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.section-title {
  position: relative;
  z-index: 1;
}

.section-title span {
  color: var(--mainColor);
  display: block;
  margin-bottom: 7px;
}

.section-title h2 {
  font-size: 35px;
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 0;
}

.section-title h2 b {
  color: var(--mainColor);
  font-weight: 700;
}

.section-title p {
  padding-top: 15px;
  margin-bottom: 0;
}

.section-title.text-center h2 {
  margin: 0 auto;
}

.section-title.text-center p {
  margin: 0 auto;
  max-width: 640px;
}

.section-bg {
  background-color: #f5f5f5;
}

/*================================================
Top Header CSS
=================================================*/
.top-header {
  padding: 15px 0;
  position: relative;
  z-index: 5;
  background-color: var(--titleColor);
}

.header-left {
  float: left;
}

.header-left ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-left ul li {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  color: var(--whiteColor);
  margin-right: 35px;
  font-size: 15px;
}

.header-left ul li:last-child {
  margin-right: 0;
}

.header-left ul li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  font-size: 20px;
  color: var(--whiteColor);
}

.header-left ul li a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.header-left ul li a:hover {
  color: var(--mainColor);
}

.header-right {
  float: right;
}

.header-right .header-language {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: -3px;
}

.header-right .header-language .dropdown-toggle {
  background-color: transparent;
  color: var(--whiteColor);
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  outline: 0;
  border: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header-right .header-language .dropdown-toggle:after {
  content: "\ea4e";
  font-family: "remixicon" !important;
  border: 0;
  margin-left: 8px;
  font-size: 20px;
  color: var(--yellowColor);
}

.header-right .header-language .dropdown-menu {
  margin: 0;
  padding: 0;
  -webkit-box-shadow: 0px 9px 54px 0px rgba(32, 32, 32, 0.1);
  box-shadow: 0px 9px 54px 0px rgba(32, 32, 32, 0.1);
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  -webkit-transform: translateY(100px) !important;
  transform: translateY(100px) !important;
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header-right .header-language .dropdown-menu.show {
  -webkit-transform: translateY(40px) !important;
  transform: translateY(40px) !important;
  opacity: 1;
  pointer-events: all;
}

.header-right .header-language .dropdown-menu a {
  padding: 5px 25px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 15px;
}

.header-right .header-language .dropdown-menu a:active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.header-right .header-language .dropdown-menu a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.header-right .header-language .dropdown-menu a img {
  width: 20px;
  margin-right: 10px;
}

.header-right .header-language .dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
}

.header-right .social-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.header-right .social-list li {
  display: inline-block;
  margin-right: 10px;
}

.header-right .social-list li:last-child {
  margin-right: 0;
}

.header-right .social-list li a {
  color: var(--whiteColor);
  font-size: var(--fontSize);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.header-right .social-list li a:hover {
  color: var(--mainColor);
}

/*================================
Navbar Area
===================================*/
.desktop-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.desktop-nav .navbar {
  background-color: var(--whiteColor);
  padding-right: 0;
  padding-top: 10px;
  padding-left: 0;
  padding-bottom: 10px;
}

.desktop-nav .navbar .navbar-brand .logo-two {
  display: none;
}

.desktop-nav .navbar ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.desktop-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: 40px;
}

.desktop-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 0;
  padding-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle {
  padding-right: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle::before {
  position: absolute;
  content: "\ea4e";
  line-height: 1;
  right: -2px;
  top: 6px;
  font-family: "remixicon" !important;
  font-size: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle::after {
  display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item a {
  font-size: 17px;
  color: var(--titleColor);
  position: relative;
  z-index: 1;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}

.desktop-nav .navbar .navbar-nav .nav-item a:hover,
.desktop-nav .navbar .navbar-nav .nav-item a:focus,
.desktop-nav .navbar .navbar-nav .nav-item a.active {
  color: #08a9e6;
}

.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover a,
.desktop-nav .navbar .navbar-nav .nav-item:focus a,
.desktop-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: var(--whiteColor);
  border: none;
  position: absolute;
  visibility: hidden;
  -webkit-transform-origin: top;
  transform-origin: top;
  border-radius: 0;
  display: block;
  width: 250px;
  z-index: 99;
  opacity: 0;
  top: 80px;
  left: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
  border-bottom: 1px solid #f0eeee;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child {
  border: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: var(--titleColor);
  padding: 12px 20px 9px;
  margin: 0;
  font-size: 15px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle {
  padding-right: 17px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a.dropdown-toggle::before {
  position: absolute;
  content: "\ea4e";
  line-height: 1;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "remixicon" !important;
  font-size: 17px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a.dropdown-toggle::after {
  display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  margin-top: 11px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -245px;
  right: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
  font-size: 15px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--titleColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  top: 100%;
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.ledu-area {
  background-color: #f5f5f5;
}

.ledu-area .navbar {
  background-color: transparent;
}

.navbar-area.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  background-color: var(--whiteColor) !important;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
}

.nav-widget-form {
  margin-left: 10px;
}

.nav-widget-form .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: 1px solid #f5f5f5;
  width: 260px;
}

.nav-widget-form .search-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 0;
  border: none;
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  margin: 0;
  border: none;
  padding: 0 25px;
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control::-webkit-input-placeholder,
.nav-widget-form .search-form .form-control:-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control::-webkit-input-placeholder,
.nav-widget-form .search-form .form-control:-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-ms-input-placeholder,
.nav-widget-form .search-form .form-control::placeholder {
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav-widget-form .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--bodyColor);
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  padding: 0 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.nav-widget-form .search-form button:hover {
  background: var(--mainColor);
  color: var(--whiteColor);
}

.nav-widget-form .search-form-bg {
  border: none;
}

.nav-widget-form .search-form-bg .form-control {
  background-color: #f5f5f5;
  border-radius: 50px;
}

.nav-widget-form .search-form-bg button {
  border-radius: 0 50px 50px 0;
}

.nav-widget-form .search-form-bg2 {
  border: none;
}

.nav-widget-form .search-form-bg2 .form-control {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.nav-widget-form .search-form-bg2 button {
  border-radius: 0 5px 5px 0;
}

.navbar-category {
  margin-left: 15px;
}

.navbar-category .category-list-dropdown {
  display: inline-block;
}

.navbar-category .category-list-dropdown .btn {
  font-weight: 500;
  font-size: 17px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: var(--bodyColor);
}

.navbar-category .category-list-dropdown .btn i {
  font-size: 19px;
  position: relative;
  top: 4px;
  display: inline-block;
  margin-right: 10px;
}

.navbar-category .category-list-dropdown .btn:hover,
.navbar-category .category-list-dropdown .btn:focus,
.navbar-category .category-list-dropdown .btn.active {
  color: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.navbar-category .category-list-dropdown .dropdown-menu {
  border: none;
  top: 170%;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 260px;
  display: block;
  border-radius: 0;
  padding: 0;
  margin-top: 0;
  position: absolute;
  visibility: hidden;
  background: var(--whiteColor);
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar-category .category-list-dropdown .dropdown-menu a {
  padding: 12px 24px;
  display: block;
  color: var(--titleColor);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  border-radius: 0;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.navbar-category .category-list-dropdown .dropdown-menu a i {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.navbar-category .category-list-dropdown .dropdown-menu a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-category .category-list-dropdown .dropdown-menu a:last-child {
  border-bottom: 0;
}

.navbar-category .category-list-dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  top: 135%;
}

.navbar-category .category-list-dropdown .dropdown-toggle::after {
  display: none;
}

.mobile-responsive-nav {
  display: none;
}

.side-nav-responsive {
  display: none;
}

.side-nav-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  right: 60px;
  top: -36px;
}

.side-nav-responsive .dot-menu .circle-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.side-nav-responsive .dot-menu .circle-inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  background-color: var(--mainColor);
}

.side-nav-responsive .dot-menu:hover .circle-inner .circle {
  background-color: var(--redColor);
}

.side-nav-responsive .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.side-nav-responsive .container .container {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 300px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.side-nav-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.side-nav-responsive .side-nav-inner {
  padding: 10px;
  -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  background-color: var(--whiteColor);
}

.side-nav-responsive .side-nav-inner .side-nav {
  background-color: var(--mainColor);
  padding: 20px 15px 10px;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item {
  position: relative;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item:last-child {
  margin-bottom: 10px;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .default-btn {
  text-align: center;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: 1px solid #f5f5f5;
  width: 230px;
}

.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control {
  background-color: var(--whiteColor);
  border-radius: 0;
  border: none;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  margin: 0;
  border: none;
  padding: 0 50px 0 15px;
  color: var(--bodyColor);
}

.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::-webkit-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control:-ms-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::-ms-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::-webkit-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control:-ms-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::-ms-input-placeholder,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control::placeholder {
  color: var(--bodyColor);
}

.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  padding: 0 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-item
  .search-form
  button:hover {
  background: var(--redColor);
  color: var(--whiteColor);
}

/*================================
Main Banner Area
===================================*/
.banner-area {
  background-color: #f5f5f5;
  z-index: 1;
  position: relative;
}

.banner-area-ptb {
  /* padding-top: 140px; */
  padding-top: 25px;
  padding-bottom: 190px;
}

.banner-content span {
  font-size: 16px;
  color: var(--mainColor);
  display: block;
  margin-bottom: 15px;
}

.banner-content h1 {
  font-size: 65px;
  color: var(--titleColor);
  margin-bottom: 20px;
  line-height: 1.2;
}
.banner-content h1 span {
  font-size: 65px;
}

.banner-content p {
  margin-bottom: 30px;
  max-width: 700px;
}

.banner-content .banner-form-area {
  margin-bottom: 30px;
}

.banner-content .banner-form-area .banner-form {
  position: relative;
  width: 650px;
}

.banner-content .banner-form-area .banner-form .form-control {
  background: var(--whiteColor);
  height: 55px;
  line-height: 55px;
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 0 25px;
  max-width: 100%;
  color: var(--bodyColor);
  width: 600px;
}

.banner-content .banner-form-area .banner-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.banner-content .banner-form-area .banner-form .default-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
  border: 0;
}

.banner-content .banner-form-area .banner-form .default-btn i {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.banner-content .banner-popular-tag {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.banner-content .banner-popular-tag li {
  display: inline-block;
}

.banner-content .banner-popular-tag li.title {
  color: var(--titleColor);
  font-weight: 600;
}

.banner-content .banner-popular-tag li a {
  color: var(--bodyColor);
  text-decoration: underline;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.banner-content .banner-popular-tag li a:hover {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.banner-content .banner-counter h3 {
  color: var(--titleColor);
  font-size: 25px;
  margin-bottom: 0;
  font-weight: 600;
  display: inline-block;
}

.banner-content .banner-counter h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 500;
}

.banner-content .banner-counter p {
  margin-bottom: 0;
  color: var(--bodyColor);
}

.banner-img {
  margin-left: 70px;
  position: relative;
  z-index: 1;
}

.banner-img .bg-shape {
  position: absolute;
  z-index: -1;
  top: 160px;
  left: -120px;
  right: 0;
  text-align: center;
}

.banner-img .bg-shape img {
  max-width: 550px;
}

.banner-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .top-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.banner-img .top-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .top-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .right-content {
  position: absolute;
  bottom: 200px;
  right: 0;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .right-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.banner-img .right-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .right-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .left-content {
  position: absolute;
  bottom: 70px;
  left: -20px;
  padding: 15px 15px 15px 65px;
  background-color: var(--whiteColor);
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .left-content img {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50%;
}

.banner-img .left-content .content {
  margin-bottom: 7px;
}

.banner-img .left-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .left-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .left-content .join-btn {
  padding: 7px 40px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  font-size: 13px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.banner-img .left-content .join-btn:hover {
  background-color: var(--titleColor);
}

.banner-img .banner-img-shape .shape1 {
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.banner-img .banner-img-shape .shape2 {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img-two {
  position: relative;
  z-index: 1;
  float: right;
  margin-right: 70px;
}

.banner-img-two img {
  max-width: 550px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img-two .bg-shape {
  position: absolute;
  z-index: -1;
  top: 25px;
  left: 0;
  right: 0;
  text-align: center;
}

.banner-img-two .bg-shape img {
  max-width: 620px;
}

.banner-shape .banner-shape1 {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.hero-slider-area {
  background-color: #f5f5f5;
  padding: 70px 0;
  position: relative;
  overflow: hidden;
}

.hero-slider-area .hero-shape .shape1 {
  position: absolute;
  top: 70px;
  right: 20px;
  -webkit-animation: rotated360 9s linear infinite;
  animation: rotated360 9s linear infinite;
}

.hero-slider-area .hero-shape .shape2 {
  position: absolute;
  bottom: 70px;
  left: 40%;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.hero-slider {
  position: relative;
  overflow: hidden;
}

.hero-slider .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.hero-slider .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  color: var(--titleColor) !important;
  border: 1px solid var(--titleColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.hero-slider .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.hero-slider .owl-nav .owl-prev:hover {
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.hero-slider .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  color: var(--titleColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  border: 1px solid var(--titleColor) !important;
}

.hero-slider .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.hero-slider .owl-nav .owl-next:hover {
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.hero-slider:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.hero-slider:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}

.hero-content {
  max-width: 640px;
}

.hero-content .top-title {
  font-size: 16px;
  color: var(--mainColor);
  display: block;
  margin-bottom: 15px;
}

.hero-content h1 {
  font-size: 60px;
  color: var(--titleColor);
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-content p {
  margin-bottom: 30px;
  max-width: 620px;
}

.hero-content .banner-btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hero-content .banner-btn .default-btn {
  margin-right: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.hero-content .banner-btn .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--whiteColor);
}

.hero-content .banner-btn .play-btn i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 18px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
}

.hero-content .banner-btn .play-btn i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid var(--whiteColor);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.hero-content .banner-btn .play-btn .title-text {
  color: var(--bodyColor);
  margin-left: 15px;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-bottom: 1px solid var(--bodyColor);
}

.hero-content .banner-btn .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.hero-content .banner-btn .play-btn:hover i::after {
  border-color: var(--mainColor);
}

.hero-content .banner-btn .play-btn:hover .title-text {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.hero-img {
  position: relative;
  z-index: 1;
}

.hero-img img {
  max-width: 550px;
}

.hero-img .hero-bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.hero-img .hero-bg-shape img {
  max-width: 582px;
}

.hero-img .hero-bg-shape .bg-img-shape2 {
  display: none !important;
}

.hero-img .hero-img-content {
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
}

.hero-img .hero-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
  border-radius: 50px;
}

.hero-img .hero-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.hero-img .hero-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.hero-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.hero-img .right-content {
  position: absolute;
  bottom: 200px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.owl-item.active .hero-content .top-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.owl-item.active .hero-content h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.owl-item.active .hero-content p {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.owl-item.active .hero-content .banner-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .hero {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .hero-bg-shape {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .top-content .hero-img-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .right-content .hero-img-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

/*================================
Inner Banner Area
===================================*/
.inner-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.inner-banner::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--titleColor);
  opacity: 0.9;
}

.inner-banner .inner-title {
  padding-top: 120px;
  padding-bottom: 120px;
}

.inner-banner .inner-title h3 {
  font-size: 36px;
  color: var(--whiteColor);
  line-height: 1.2;
  margin-bottom: 15px;
  max-width: 630px;
}

.inner-banner .inner-title.text-center h3 {
  margin-left: auto;
  margin-right: auto;
}

.inner-banner .inner-title .rating {
  color: var(--whiteColor);
  margin-bottom: 20px;
}

.inner-banner .inner-title .rating i {
  color: #ffc107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.inner-banner .inner-title .inner-banner-content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-bottom: 20px;
}

.inner-banner .inner-title .inner-banner-content .user-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inner-banner .inner-title .inner-banner-content .user-area img {
  border-radius: 50%;
  margin-right: 10px;
}

.inner-banner .inner-title .inner-banner-content .user-area h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  color: var(--whiteColor);
}

.inner-banner .inner-title .inner-banner-content .user-area:hover h3 {
  color: var(--mainColor);
}

.inner-banner .inner-title .inner-banner-content .course-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.inner-banner .inner-title .inner-banner-content .course-list li {
  display: inline-block;
  margin-right: 20px;
  color: var(--whiteColor);
  font-size: 16px;
}

.inner-banner .inner-title .inner-banner-content .course-list li::before {
  display: none;
}

.inner-banner .inner-title .inner-banner-content .course-list li:last-child {
  margin-right: 0;
}

.inner-banner .inner-title .inner-banner-content .course-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.inner-banner .inner-title ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inner-banner .inner-title ul li {
  font-size: 18px;
  color: var(--mainColor);
  display: inline-block;
  position: relative;
  margin-right: 20px;
  font-weight: 400;
}

.inner-banner .inner-title ul li:last-child {
  margin-right: 0;
}

.inner-banner .inner-title ul li:last-child::before {
  display: none;
}

.inner-banner .inner-title ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -14px;
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
  width: 1.5px;
  height: 20px;
  background-color: var(--whiteColor);
}

.inner-banner .inner-title ul li a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.inner-banner .inner-title ul li a:hover {
  color: var(--mainColor);
}

/* .inner-banner-bg {
  background-image: url(../images/inner-banner/inner-banner1.jpg);
}

.inner-banner-bg2 {
  background-image: url(../images/inner-banner/inner-banner2.jpg);
}

.inner-banner-bg3 {
  background-image: url(../images/inner-banner/inner-banner3.jpg);
}

.inner-banner-bg4 {
  background-image: url(../images/inner-banner/inner-banner4.jpg);
}

.inner-banner-bg5 {
  background-image: url(../images/inner-banner/inner-banner5.jpg);
}

.inner-banner-bg6 {
  background-image: url(../images/inner-banner/inner-banner6.jpg);
}

.inner-banner-bg7 {
  background-image: url(../images/inner-banner/inner-banner7.jpg);
}

.inner-banner-bg8 {
  background-image: url(../images/inner-banner/inner-banner8.jpg);
}

.inner-banner-bg9 {
  background-image: url(../images/inner-banner/inner-banner9.jpg);
}

.inner-banner-bg10 {
  background-image: url(../images/inner-banner/inner-banner10.jpg);
}

.inner-banner-bg11 {
  background-image: url(../images/inner-banner/inner-banner11.jpg);
}

.inner-banner-bg12 {
  background-image: url(../images/inner-banner/inner-banner12.jpg);
} */

/*================================
Counter Area
===================================*/
.counter-area {
  background-color: var(--titleColor);
}

.counter-content {
  padding-left: 90px;
  position: relative;
  margin-bottom: 30px;
}

.counter-content i {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 0;
  font-size: 55px;
  line-height: 1;
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.counter-content i::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -5px;
  right: -15px;
  width: 43px;
  height: 40px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.7;
}

.counter-content h3 {
  color: var(--whiteColor);
  font-size: 35px;
  margin-bottom: 0;
  font-weight: 500;
}

.counter-content h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 500;
}

.counter-content p {
  margin-bottom: 0;
  color: var(--whiteColor);
}

.counter-content:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.counter-content:hover i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.counter-area-two {
  position: relative;
  z-index: 1;
}

.counter-area-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: #f5f5f5;
}

.counter-max {
  background-color: var(--mainColor);
  padding: 100px 60px 100px 100px;
  border-radius: 10px;
}

.counter-max .counter-content i::after {
  background-color: var(--whiteColor);
  opacity: 0.5;
}

.counter-card {
  padding: 30px 30px 30px 120px;
  position: relative;
  background-color: var(--whiteColor);
  margin-bottom: 30px;
}

.counter-card i {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 30px;
  font-size: 55px;
  line-height: 1;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.counter-card i::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -5px;
  right: -15px;
  width: 37px;
  height: 35px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.5;
}

.counter-card h3 {
  font-size: 35px;
  margin-bottom: 0;
  font-weight: 500;
}

.counter-card h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 500;
}

.counter-card p {
  margin-bottom: 0;
}

.counter-card:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.counter-card:hover i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*================================
Featured Area
===================================*/
.featured-area-mt {
  position: relative;
  margin-top: -50px;
  z-index: 2;
}

.featured-card {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.featured-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.featured-card a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured-card a i {
  width: 60px;
  height: 60px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  margin-right: 15px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-card a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-card:hover::before {
  opacity: 1;
  width: 100%;
}

.featured-card:hover a i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.featured-card:hover a h3 {
  color: var(--whiteColor);
}

.featured-item {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 35px 35px 35px 110px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  min-height: 213px;
}

.featured-item i {
  position: absolute;
  top: 35px;
  left: 35px;
  width: 55px;
  height: 55px;
  line-height: 60px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  font-size: 22px;
  border: 1px dashed var(--mainColor);
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.featured-item h3 {
  margin-bottom: 10px;
}

.featured-item p {
  margin-bottom: 0;
}

.featured-item:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.featured-item-two {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 10px;
}

.featured-item-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  border-radius: 10px;
}

.featured-item-two a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured-item-two a i {
  padding-top: 10px;
  padding-left: 10px;
  position: relative;
  font-size: 55px;
  text-align: center;
  line-height: 1;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
  margin-right: 18px;
}

.featured-item-two a i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  width: 47px;
  height: 47px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.1;
}

.featured-item-two a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-item-two:hover::before {
  opacity: 1;
  width: 100%;
}

.featured-item-two:hover a i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  color: var(--whiteColor);
}

.featured-item-two:hover a i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background: var(--whiteColor);
  opacity: 0.15;
}

.featured-item-two:hover a h3 {
  color: var(--whiteColor);
}

.categories-area {
  position: relative;
}

.categories-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.categories-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.categories-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.categories-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.categories-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.categories-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.categories-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.categories-item {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 10px;
}

.categories-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 0;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  border-radius: 10px;
}

.categories-item a {
  display: block;
}

.categories-item a img {
  border-radius: 10px 10px 0 0;
}

.categories-item .content {
  padding: 30px 20px;
}

.categories-item .content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.categories-item .content a i {
  width: 60px;
  height: 60px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  margin-right: 20px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.categories-item .content a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.categories-item:hover::before {
  opacity: 1;
  height: 100%;
}

.categories-item:hover .content a i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.categories-item:hover .content a h3 {
  color: var(--whiteColor);
}

/*=================================
Courses Area
====================================*/
.courses-area {
  position: relative;
}

.courses-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.courses-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.courses-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.courses-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two {
  position: relative;
  overflow: hidden;
}

.courses-area-two .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.courses-area-two .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area-two .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.courses-area-two .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area-two .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.courses-area-two .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.courses-area-two:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}

.courses-item {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: rgba(0, 0, 0, 0.06) 0 0 15px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 15px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.courses-item a {
  display: block;
}

.courses-item .content {
  padding: 20px;
  position: relative;
}

.courses-item .content .course-instructors {
  position: absolute;
  right: 30px;
  top: -50px;
}

.courses-item .content .course-instructors img {
  border-radius: 50%;
  border: 2px solid var(--whiteColor);
}

.courses-item .content .tag-btn {
  background-color: rgba(8, 169, 230, 0.1);
  padding: 5px 20px;
  color: var(--mainColor);
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 500;
}

.courses-item .content .tag-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-item .content .price-text {
  position: absolute;
  top: 30px;
  right: 30px;
  color: var(--mainColor);
  font-size: 22px;
  font-weight: 600;
}

/* .courses-item .content h3 {
  margin-top: 17px;
  margin-bottom: 17px;
} */

.courses-item .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-item .content .course-list {
  margin: 0 0 15px;
  padding: 0 0 15px;
  list-style-type: none;
  border-bottom: 1px solid #e7e5e5;
}

.courses-item .content .course-list li {
  display: inline-block;
  margin-right: 30px;
  color: var(--bodyColor);
}

.courses-item .content .course-list li:last-child {
  margin-right: 0;
}

.courses-item .content .course-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.courses-item .content .bottom-content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.courses-item .content .bottom-content .user-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-item .content .bottom-content .user-area img {
  border-radius: 50%;
  margin-right: 10px;
}

.courses-item .content .bottom-content .user-area h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.courses-item .content .bottom-content .user-area:hover h3 {
  color: var(--mainColor);
}

.courses-item .content .bottom-content .rating {
  color: var(--bodyColor);
}

.courses-item .content .bottom-content .rating i {
  color: #ffc107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.courses-item .content .bottom-content .rating2 {
  color: var(--bodyColor);
}

.courses-item .content .bottom-content .rating2 i {
  color: #ffc107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.courses-item .content .bottom-content .bottom-price {
  color: var(--mainColor);
  font-size: 22px;
  font-weight: 600;
}

.courses-item:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.courses-item:hover .content h3 a {
  color: var(--mainColor);
}

.courses-page-side-bar-widget {
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid #f5f5f5;
}

.courses-page-side-bar-widget .title {
  font-size: 22px;
  margin-bottom: 20px;
}

.courses-page-side-bar-widget .courses-page-content {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.courses-page-side-bar-widget .courses-page-content li {
  display: block;
  margin-bottom: 10px;
}

.courses-page-side-bar-widget .courses-page-content li:last-child {
  margin-bottom: 0;
}

.courses-page-side-bar-widget .courses-page-content li label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--bodyColor);
  width: 100%;
}

.courses-page-side-bar-widget .courses-page-content li label span {
  text-align: center;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  height: 24px;
  background-color: #dff3ef;
  color: var(--mainColor);
  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 14px;
}

.courses-page-side-bar-widget .courses-page-content li label span.fl {
  float: right;
}

.courses-page-side-bar-widget .courses-page-content li label span:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-page-side-bar-widget .courses-page-content li label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #fff;
  border: 1px solid #ecedf2;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  font-family: "remixicon" !important;
  color: #fff;
  font-size: 9px;
  font-weight: 900;
  text-align: center;
  line-height: 17px;
}

.courses-page-side-bar-widget .courses-page-content li input[type="checkbox"] {
  display: none;
}

.courses-page-side-bar-widget
  .courses-page-content
  li
  input[type="checkbox"]:checked
  + label:before {
  content: "\eb7b";
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  font-family: "remixicon" !important;
}

.courses-page-side-bar-widget .courses-page-content li:hover span {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-details-tab .tabs {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}

.courses-details-tab .tabs li {
  display: inline-block;
  margin-right: 20px;
  color: var(--titleColor);
  padding: 12px 25px;
  background-color: #f5f5f5;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-tab .tabs li:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li.active {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

.courses-details-tab-content .event-details-mt-30 {
  margin-top: 30px;
}

.courses-details-tab-content h1,
.courses-details-tab-content h2,
.courses-details-tab-content h3,
.courses-details-tab-content h4,
.courses-details-tab-content h5,
.courses-details-tab-content h6 {
  margin-bottom: 15px;
}

.courses-details-tab-content h1 {
  font-size: 32px;
}

.courses-details-tab-content h2 {
  font-size: 29px;
}

.courses-details-tab-content h3 {
  font-size: 26px;
}

.courses-details-tab-content h4 {
  font-size: 23px;
}

.courses-details-tab-content h5 {
  font-size: 20px;
}

.courses-details-tab-content h6 {
  font-size: 17px;
}

.courses-details-tab-content a {
  color: var(--mainColor);
}

.courses-details-tab-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--mainColor);
}

.courses-details-tab-content ul,
.courses-details-tab-content ol {
  margin-bottom: 30px;
}

.courses-details-tab-content ul li,
.courses-details-tab-content ol li {
  margin-bottom: 10px;
}

.courses-details-tab-content ul li:last-child,
.courses-details-tab-content ol li:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content p {
  margin-bottom: 15px;
}

.courses-details-tab-content .courses-details-into {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-into .courses-details-list {
  margin: 0 0 15px;
  list-style-type: none;
  padding: 0;
}

.courses-details-tab-content .courses-details-into .courses-details-list li {
  display: block;
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
  padding-left: 25px;
}

.courses-details-tab-content
  .courses-details-into
  .courses-details-list
  li:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content
  .courses-details-into
  .courses-details-list
  li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: var(--mainColor);
}

.courses-details-tab-content .courses-details-accordion {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-item {
  display: block;
  margin-bottom: 15px;
  border: 1px solid #e7e7ec;
  background-color: var(--whiteColor);
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-item:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-title {
  color: var(--titleColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headerFonts);
  padding: 20px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-title
  i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 30px;
  line-height: 30px;
  color: var(--titleColor);
  text-align: center;
  background-color: transparent;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-title.active {
  color: var(--mainColor);
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-title.active
  i {
  color: var(--mainColor);
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-title.active
  i::before {
  content: "\f1af";
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content {
  display: none;
  position: relative;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e7e7ec;
  padding: 20px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list
  .accordion-content-left
  i {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list
  .accordion-content-right
  span {
  margin-right: 10px;
  color: #adadad;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list
  .accordion-content-right
  .tag {
  display: inline-block;
  padding: 7px 15px;
  color: var(--mainColor);
  font-size: 14px;
  background-color: rgba(8, 169, 230, 0.1);
  margin-right: 10px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list
  .accordion-content-right
  .tag2 {
  display: inline-block;
  padding: 7px 15px;
  color: var(--redColor);
  font-size: 14px;
  background-color: rgba(236, 39, 47, 0.1);
  margin-right: 10px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content
  .accordion-content-list
  .accordion-content-right
  i {
  color: var(--bodyColor);
  font-size: 18px;
  position: relative;
  top: 5px;
}

.courses-details-tab-content
  .courses-details-accordion
  .accordion
  .accordion-content.show {
  display: block;
}

.courses-details-tab-content .courses-details-instructor {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-instructor h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.courses-details-tab-content .courses-details-instructor .details-instructor {
  padding: 10px 10px 10px 90px;
  position: relative;
  margin-bottom: 20px;
}

.courses-details-tab-content
  .courses-details-instructor
  .details-instructor
  img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.courses-details-tab-content
  .courses-details-instructor
  .details-instructor
  h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.courses-details-tab-content
  .courses-details-instructor
  .details-instructor
  span {
  margin-bottom: 0;
}

.courses-details-tab-content .courses-details-instructor .course-list {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #e7e5e5;
}

.courses-details-tab-content .courses-details-instructor .course-list li {
  display: inline-block;
  margin-right: 30px;
  color: var(--bodyColor);
}

.courses-details-tab-content
  .courses-details-instructor
  .course-list
  li:last-child {
  margin-right: 0;
}

.courses-details-tab-content .courses-details-instructor .course-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.courses-details-tab-content .courses-details-review-form .review-title {
  position: relative;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-title
  .rating {
  display: inline-block;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-title
  .rating
  i {
  color: #ffc107;
  font-size: 14px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-title
  .rating
  p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-title
  .btn-right {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 7px 15px;
  border-radius: 5px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-title
  .btn-right:hover {
  background-color: var(--titleColor);
  color: var(--whiteColor);
}

.courses-details-tab-content .courses-details-review-form .review-comments {
  margin-top: 35px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .content {
  position: relative;
  z-index: 1;
  padding: 3px 0 3px 80px;
  margin-bottom: 15px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .content
  img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  max-width: 60px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .content
  .content-dtls
  h4 {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .content
  .content-dtls
  span {
  color: var(--mainColor);
  font-size: 14px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .rating
  i {
  color: #ffc107;
  font-size: 14px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  h3 {
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: var(--titleColor);
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  p {
  margin-bottom: 0;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .review-report-link {
  position: absolute;
  right: 0;
  color: rgba(218, 29, 37, 0.53);
  top: 40px;
  text-decoration: underline;
  font-weight: 600;
  font-size: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-tab-content
  .courses-details-review-form
  .review-comments
  .review-item
  .review-report-link:hover {
  color: var(--mainColor);
}

.courses-details-tab-content .courses-details-review-form .review-form {
  margin-top: 25px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-form
  .contact-form {
  max-width: 100%;
  margin: 0 auto 30px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-form
  .contact-form
  h4 {
  margin-bottom: 25px;
}

.courses-details-tab-content
  .courses-details-review-form
  .review-form
  .contact-form
  .form-group
  .form-control {
  background-color: #f5f5f5;
}

.courses-details-sidebar {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 2;
  margin-top: -250px;
  margin-bottom: 30px;
}

.courses-details-sidebar .content {
  padding: 30px;
}

.courses-details-sidebar .content h3 {
  margin-bottom: 10px;
}

.courses-details-sidebar .content span {
  color: var(--titleColor);
  margin-bottom: 10px;
  font-weight: 600;
}

.courses-details-sidebar .content .courses-details-list {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
}

.courses-details-sidebar .content .courses-details-list li {
  display: block;
  margin-bottom: 5px;
  color: var(--bodyColor);
}

.courses-details-sidebar .content .courses-details-list li:last-child {
  margin-bottom: 0;
}

.courses-details-sidebar .content .default-btn {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.courses-details-sidebar .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.courses-details-sidebar .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.courses-details-sidebar .social-link li.social-title {
  font-size: 18px;
  color: var(--titleColor);
}

.courses-details-sidebar .social-link li:last-child {
  margin-right: 0;
}

.courses-details-sidebar .social-link li a {
  display: inline-block;
}

.courses-details-sidebar .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  border: 1px solid var(--mainColor);
}

.courses-details-sidebar .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.product-topper .product-title h3 {
  font-family: var(--bodyFonts);
  font-size: 16px;
  color: var(--bodyColor);
  margin-bottom: 0;
  font-weight: 500;
}

.product-topper .product-title h3 span {
  color: var(--mainColor);
}

.product-topper .product-list {
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  border: 1px solid #eeeeee;
}

.product-topper .product-list i {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 15px;
  color: var(--bodyColor);
  font-size: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.product-topper .product-list .form-select {
  color: var(--bodyColor);
  background-color: transparent;
  border: 0;
  background-image: unset;
  padding: 12px 40px 12px 20px;
}

.product-topper .product-list .form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product-topper .product-list .form-select:focus i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.product-topper .product-list .form-select option {
  color: var(--bodyColor);
  padding-top: 5px;
  padding-bottom: 5px;
}

.product-topper .product-list:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product-topper .product-list:hover i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*=================================
Cart Area
====================================*/
.cart-wraps-area .cart-wraps {
  margin-bottom: 30px;
}

.cart-wraps-area .cart-table table {
  margin-bottom: 0;
}

.cart-wraps-area .cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 15px 0;
  border: none;
  font-weight: 700;
  font-size: 18px;
}

.cart-wraps-area .cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--titleColor);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  border-color: #eeeeee;
  border-left: none;
  border-right: none;
}

.cart-wraps-area .cart-table table tbody tr td.courses-thumbnail a {
  display: block;
}

.cart-wraps-area .cart-table table tbody tr td.courses-thumbnail a img {
  width: 100px;
  height: 100px;
  background-color: #f2f6fe;
  padding: 3px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-name a {
  color: var(--titleColor);
  font-weight: 600;
  display: inline-block;
  font-size: 16px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-name a:hover {
  color: var(--mainColor) !important;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove {
  color: var(--bodyColor);
  float: right;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove i {
  font-size: 24px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove:hover {
  color: var(--greenColor);
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal span {
  font-weight: 600;
}

.cart-wraps-area .cart-table .table > :not(:first-child) {
  border-top: none;
}

.cart-wraps-area .cart-buttons {
  margin-top: 30px;
}

.cart-totals {
  border-radius: 0;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  padding: 40px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.cart-totals h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  color: var(--bodyColor);
  overflow: hidden;
  font-weight: 500;
}

.cart-totals ul li span {
  float: right;
  color: var(--titleColor);
  font-weight: normal;
}

/*=================================
Checkout Area
===================================*/
.checkout-user {
  color: var(--titleColor);
  font-size: 16px;
  margin-bottom: 30px;
  border-left: 2px solid var(--mainColor);
  padding: 15px 25px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
}

.checkout-user span {
  color: var(--bodyColor);
  font-size: 18px;
}

.checkout-user span a {
  color: var(--mainColor);
}

.checkout-user span a:hover {
  color: var(--titleColor);
}

.billing-details {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  padding: 50px 30px 25px 30px;
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
}

.billing-details h3 {
  font-size: 24px;
  color: var(--titleColor);
  margin-bottom: 25px;
  font-weight: 600;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  color: var(--titleColor);
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.billing-details .form-group label span {
  color: var(--mainColor);
}

.billing-details .form-group .form-control {
  height: 50px;
  color: var(--bodyColor);
  border: none;
  background-color: #f9f8f8;
  border-radius: 0;
  padding: 10px 20px;
  width: 100%;
}

.billing-details .form-group .form-control:focus,
.billing-details .form-group .form-control :hover {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group .form-message {
  font-size: 16px;
  border: none;
  background-color: #f9f8f8;
  padding: 18px 18px;
  font-weight: 400;
  width: 100%;
}

.billing-details .form-group .form-message:focus,
.billing-details .form-group .form-message :hover {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-check {
  margin-bottom: 15px;
}

.billing-details .form-check .form-check-input {
  width: 15px;
  height: 15px;
}

.billing-details .form-check .form-check-label {
  color: var(--titleColor);
  margin-left: 5px;
  font-weight: 500;
}

.billing-totals {
  border-radius: 0;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  padding: 35px 30px 40px;
  margin-bottom: 30px;
}

.billing-totals h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.billing-totals ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.billing-totals ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  color: var(--bodyColor);
  overflow: hidden;
  font-weight: 500;
}

.billing-totals ul li span {
  float: right;
  color: var(--titleColor);
  font-weight: normal;
}

.payment-box {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px;
}

.payment-box .payment-method h3 {
  font-size: 22px;
  margin-bottom: 20px;
}

.payment-box .payment-method p [type="radio"]:checked {
  display: none;
}

.payment-box .payment-method p [type="radio"]:checked + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: var(--titleColor);
  position: relative;
  margin-bottom: 15px;
}

.payment-box .payment-method p [type="radio"]:checked + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.payment-box .payment-method p [type="radio"]:checked + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.payment-box .payment-method p [type="radio"]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #172541;
  position: relative;
  margin-bottom: 15px;
}

.payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.payment-box .default-btn {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.pricing-card {
  margin-bottom: 30px;
  background-color: #f5f5f5;
  padding: 40px;
}

.pricing-card .top-content {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 25px;
}

.pricing-card .top-content h3 {
  font-size: 30px;
  margin-bottom: 10px;
}

.pricing-card .top-content h2 {
  font-size: 50px;
  color: var(--mainColor);
  margin-bottom: 0;
  font-weight: 500;
}

.pricing-card .top-content h2 sub {
  font-size: 20px;
  font-weight: 400;
}

.pricing-card ul {
  margin: 0 0 25px;
  padding: 0;
  list-style-type: none;
}

.pricing-card ul li {
  display: block;
  margin-bottom: 10px;
  color: var(--bodyColor);
}

.pricing-card ul li i {
  color: var(--mainColor);
  position: relative;
  top: 2px;
  margin-right: 10px;
  font-size: 18px;
}

/*=================================
Play Area
====================================*/
.play-area {
  position: relative;
  z-index: 1;
}

.play-area .title h2 {
  font-size: 22px;
}

.play-area .title h2 span {
  color: var(--mainColor);
}

.play-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70%;
  background-color: #f5f5f5;
}

.brand-item {
  text-align: center;
}

.brand-item img {
  width: unset !important;
  text-align: center;
  cursor: pointer;
  opacity: 0.3;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.brand-item .brand-item-logo2 {
  display: none !important;
}

.brand-item:hover img {
  opacity: 1;
}

.play-btn-area {
  /* background-image: url(../images/play-btn.jpg); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 1100px;
  margin: 0 auto;
  padding: 200px 0;
  text-align: center;
}

.play-btn-area .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.play-btn-area .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 95px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.play-btn-area .play-btn i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.play-btn-area .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.play-btn-area .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}

/*=================================
FAQ Area
====================================*/
.faq-img {
  position: relative;
  margin-bottom: 30px;
}

.faq-img img {
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
}

.faq-img .shape {
  position: absolute;
  bottom: 100px;
  right: -10px;
}

.faq-img .shape img {
  -webkit-animation: none;
  animation: none;
}

.faq-accordion {
  margin-bottom: 30px;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 20px;
  border: none;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  color: var(--titleColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headerFonts);
  padding: 8px 0 8px 55px;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: var(--titleColor);
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  text-align: center;
}

.faq-accordion .accordion .accordion-title.active {
  color: var(--mainColor);
}

.faq-accordion .accordion .accordion-title.active i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f1af";
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: 15px;
  padding: 0;
}

.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
  font-size: 15px;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-content p:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

/*=================================
Instructors Area
====================================*/
.instructors-area {
  position: relative;
}

.instructors-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.instructors-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.instructors-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.instructors-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.instructors-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.instructors-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.instructors-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.instructors-item {
  margin-bottom: 30px;
}

.instructors-item .instructors-img {
  position: relative;
}

.instructors-item .instructors-img a {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.instructors-item .instructors-img a img {
  margin: 0 auto;
}

.instructors-item .instructors-img .instructors-social {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
}

.instructors-item .instructors-img .instructors-social li {
  display: block;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 10px;
}

.instructors-item .instructors-img .instructors-social li:last-child {
  margin-bottom: 0;
}

.instructors-item .instructors-img .instructors-social li a {
  display: inline-block;
}

.instructors-item .instructors-img .instructors-social li a i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 16px;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  border-radius: 50px;
}

.instructors-item .instructors-img .instructors-social li a:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.instructors-item .instructors-img .instructors-social li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.instructors-item .content {
  padding-top: 20px;
  text-align: center;
}

.instructors-item .content h3 {
  margin-bottom: 5px;
}

.instructors-item .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-item .content span {
  color: var(--bodyColor);
}

.instructors-item:hover .instructors-img .instructors-social li {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.instructors-item:hover .content h3 a {
  color: var(--mainColor);
}

.instructors-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.instructors-card a {
  display: block;
  margin: 0 auto;
}

.instructors-card a img {
  margin: 0 auto;
}

.instructors-card .content {
  padding: 30px;
  position: relative;
}

.instructors-card .content .instructors-social {
  margin: 0;
  list-style-type: none;
  padding: 0;
  position: absolute;
  right: 85px;
  bottom: 40px;
}

.instructors-card .content .instructors-social li {
  display: inline-block;
  position: absolute;
  bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-card .content .instructors-social li.share-btn i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50px;
  background-color: rgba(8, 169, 230, 0.1);
  color: var(--mainColor);
  display: inline-block;
  margin: 0 8px;
  font-size: 24px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.instructors-card .content .instructors-social li.share-btn i:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.instructors-card .content .instructors-social li:nth-child(1) {
  z-index: 1;
}

.instructors-card .content .instructors-social li:nth-child(2),
.instructors-card .content .instructors-social li:nth-child(3),
.instructors-card
  .content
  .instructors-social
  li:nth-child(4)
  .instructors-card
  .content
  .instructors-social
  li:nth-child(5) {
  bottom: 0;
}

.instructors-card .content .instructors-social li a {
  color: var(--whiteColor);
}

.instructors-card .content .instructors-social li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 45px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  text-align: center;
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
  margin: 0 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  opacity: 0;
}

.instructors-card .content .instructors-social li a:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  opacity: 1;
}

.instructors-card .content .instructors-social:hover li a i {
  opacity: 1;
}

.instructors-card .content .instructors-social:hover li:nth-child(1) {
  z-index: 1;
}

.instructors-card .content .instructors-social:hover li:nth-child(2) {
  bottom: 65px;
}

.instructors-card .content .instructors-social:hover li:nth-child(3) {
  bottom: 122px;
}

.instructors-card .content .instructors-social:hover li:nth-child(4) {
  bottom: 178px;
}

.instructors-card .content .instructors-social:hover li:nth-child(5) {
  bottom: 235px;
}

.instructors-card .content h3 {
  margin-bottom: 5px;
}

.instructors-card .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-card .content span {
  color: var(--bodyColor);
}

.instructors-item-bg {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.instructors-item-bg .instructors-img .instructors-social li a i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.instructors-item-bg .content {
  padding: 20px;
}

.instructors-item-bg .content h3 {
  font-size: 20px;
}

.instructors-details-img {
  margin-bottom: 30px;
}

.instructors-details-img img {
  margin-bottom: 25px;
}

.instructors-details-img .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.instructors-details-img .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.instructors-details-img .social-link li.social-title {
  font-size: 18px;
  color: var(--titleColor);
}

.instructors-details-img .social-link li:last-child {
  margin-right: 0;
}

.instructors-details-img .social-link li a {
  display: inline-block;
}

.instructors-details-img .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: transparent;
  border: 1px solid var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
}

.instructors-details-img .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.instructors-details-content {
  margin-bottom: 30px;
}

.instructors-details-content h3 {
  margin-bottom: 5px;
}

.instructors-details-content .sub-title {
  margin-bottom: 25px;
  display: block;
}

.instructors-details-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.instructors-details-content ul li {
  display: block;
  margin-bottom: 10px;
  color: var(--titleColor);
}

.instructors-details-content ul li span {
  color: var(--bodyColor);
  margin-left: 10px;
}

.instructors-details-content ul li span i {
  position: relative;
  top: 2px;
  color: #ffc107;
  margin-right: 3px;
}

.instructors-details-content ul li span a {
  color: var(--bodyColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.instructors-details-content ul li span a:hover {
  color: var(--mainColor);
}

/*================================
Testimonials Area
===================================*/
.testimonials-area {
  position: relative;
  overflow: hidden;
}

.testimonials-area .owl-dots {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.testimonials-area .owl-dots .owl-dot span {
  background-color: rgba(33, 34, 95, 0.2) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  margin: 0 7px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.testimonials-area .owl-dots .owl-dot.active span {
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.testimonials-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.testimonials-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.testimonials-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.testimonials-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.testimonials-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.testimonials-area:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.testimonials-area:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}

.testimonials-area .testimonials-card-two {
  margin-top: 45px;
  margin-left: 10px;
  margin-right: 10px;
}

.testimonials-item {
  position: relative;
  z-index: 1;
  margin-top: 45px;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-item img {
  position: relative;
  margin-top: -75px;
  width: 80px !important;
  border-radius: 50%;
  margin-bottom: 15px;
}

.testimonials-item .rating {
  color: #ffc107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 10px;
}

.testimonials-item .rating i {
  display: inline-block;
}

.testimonials-item p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item .quote {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 40px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-card .content {
  padding-left: 100px;
  position: relative;
  margin-bottom: 30px;
}

.testimonials-card .content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px !important;
  border-radius: 50%;
}

.testimonials-card .content h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card .content span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card .rating {
  color: #ffc107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 0;
}

.testimonials-card .rating i {
  display: inline-block;
}

.testimonials-card .quote {
  position: absolute;
  top: 35px;
  right: 30px;
  font-size: 45px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-card-two p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .rating {
  color: #ffc107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 0;
}

.testimonials-card-two .rating i {
  display: inline-block;
}

.testimonials-card-two .content {
  padding-left: 100px;
  position: relative;
  margin-bottom: 0;
}

.testimonials-card-two .content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px !important;
  border-radius: 50%;
}

.testimonials-card-two .content h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .content span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .quote {
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 45px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

/*================================
Enrolled Area
===================================*/
.enrolled-area {
  background-color: #f5f5f5;
}

.enrolled-content .section-title {
  margin-bottom: 20px;
}

.enrolled-content .enrolled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.enrolled-content .enrolled-list li {
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 55px;
  position: relative;
  font-size: 18px;
  color: var(--titleColor);
  margin-bottom: 20px;
  font-weight: 600;
}

.enrolled-content .enrolled-list li i {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 47px;
  border-radius: 50px;
  text-align: center;
  background-color: rgba(8, 169, 230, 0.1);
  color: var(--mainColor);
}

.enrolled-img {
  margin-top: 50px;
  position: relative;
  z-index: 1;
  margin-left: 70px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.enrolled-img .bg-shape {
  position: absolute;
  z-index: -1;
  top: 100px;
  left: -30px;
  right: 0;
  text-align: center;
}

.enrolled-img .enrolled-img-content {
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.enrolled-img .enrolled-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.enrolled-img .enrolled-img-content i.active {
  background-color: #08a9e6;
}

.enrolled-img .enrolled-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.enrolled-img .enrolled-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.enrolled-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .right-content {
  position: absolute;
  bottom: 200px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .left-content {
  position: absolute;
  bottom: 70px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .enrolled-img-shape .shape1 {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.enrolled-img-two img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img-three {
  margin-bottom: 30px;
  position: relative;
}

.enrolled-img-three img {
  max-width: 600px;
  border-radius: 10px;
}

.enrolled-img-three .enrolled-img-content {
  position: absolute;
  bottom: 40px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.enrolled-img-three .enrolled-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
  border-radius: 50px;
}

.enrolled-img-three .enrolled-img-content i.active {
  background-color: #08a9e6;
}

.enrolled-img-three .enrolled-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.enrolled-img-three .enrolled-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

/*================================
Event Area
===================================*/
.event-area .owl-dots {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.event-area .owl-dots .owl-dot span {
  background-color: rgba(33, 34, 95, 0.2) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  margin: 0 7px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.event-area .owl-dots .owl-dot.active span {
  background-color: var(--mainColor) !important;
}

.event-area .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor) !important;
}

.event-item {
  padding: 30px 20px;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.event-item .event-img {
  margin-right: 20px;
  max-width: 180px;
}

.event-item .event-img a {
  display: block;
}

.event-item .event-img a img {
  border-radius: 10px;
}

.event-item .event-content .event-list {
  margin: 0 0 12px;
  padding: 0;
  list-style-type: none;
}

.event-item .event-content .event-list li {
  display: inline-block;
  margin-right: 10px;
  color: var(--bodyColor);
}

.event-item .event-content .event-list li:last-child {
  margin-right: 0;
}

.event-item .event-content .event-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.event-item .event-content h3 {
  margin-bottom: 10px;
}

.event-item .event-content h3 a {
  color: var(--titleColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.event-item .event-content p {
  margin-bottom: 0;
}

.event-item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.event-item:hover .event-content h3 a {
  color: var(--mainColor);
}

.event-details-content .event-details-mt-30 {
  margin-top: 30px;
}

.event-details-content h1,
.event-details-content h2,
.event-details-content h3,
.event-details-content h4,
.event-details-content h5,
.event-details-content h6 {
  margin-bottom: 15px;
}

.event-details-content h1 {
  font-size: 32px;
}

.event-details-content h2 {
  font-size: 29px;
}

.event-details-content h3 {
  font-size: 26px;
}

.event-details-content h4 {
  font-size: 23px;
}

.event-details-content h5 {
  font-size: 20px;
}

.event-details-content h6 {
  font-size: 17px;
}

.event-details-content a {
  color: var(--goldenColor);
}

.event-details-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--goldenColor);
}

.event-details-content ul,
.event-details-content ol {
  margin-bottom: 30px;
}

.event-details-content ul li,
.event-details-content ol li {
  margin-bottom: 10px;
}

.event-details-content ul li:last-child,
.event-details-content ol li:last-child {
  margin-bottom: 0;
}

.event-details-content p {
  margin-bottom: 15px;
}

.event-details-content .event-preview-img {
  margin-bottom: 25px;
}

.event-sidebar {
  margin-bottom: 35px;
  background-color: #f5f5f5;
  padding: 35px 23px;
}

.event-sidebar .title {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
}

.event-sidebar .title::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 3px;
  background-color: var(--mainColor);
}

.event-sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.event-sidebar ul li {
  display: block;
  color: var(--mainColor);
  margin-bottom: 10px;
}

.event-sidebar ul li:last-child {
  margin-bottom: 0;
}

.event-sidebar ul li span {
  color: var(--bodyColor);
  float: right;
}

.event-popular-post {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.event-popular-post .item {
  overflow: hidden;
  margin-bottom: 12px;
}

.event-popular-post .item:last-child {
  margin-bottom: 0;
}

.event-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.event-popular-post .item .thumb .full-image {
  width: 100px;
  height: 115px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: var(--whiteColor);
}

.event-popular-post .item .thumb .full-image.bg1 {
  /* background-image: url(../images/events/event-img2.jpg); */
}

.event-popular-post .item .thumb .full-image.bg2 {
  /* background-image: url(../images/events/event-img3.jpg); */
}

.event-popular-post .item .info {
  overflow: hidden;
  padding: 4px 0;
}

.event-popular-post .item .info p {
  color: var(--bodyColor);
  margin-bottom: 0;
}

.event-popular-post .item .info .title-text {
  margin-bottom: 7px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 600;
  max-width: 300px;
}

.event-popular-post .item .info .title-text a {
  display: inline-block;
  color: var(--titleColor);
}

.event-popular-post .item .info .title-text a:hover {
  color: var(--mainColor);
}

/*================================
Contact Area
===================================*/
.contact-info-card {
  padding: 30px 30px 30px 110px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  margin-bottom: 30px;
}

.contact-info-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.contact-info-card i {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  text-align: center;
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
}

.contact-info-card h3 {
  margin-bottom: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p {
  margin-bottom: 0;
  color: var(--bodyColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p a {
  color: var(--bodyColor);
  display: block;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p a:hover {
  color: var(--whiteColor);
}

.contact-info-card:hover::before {
  opacity: 1;
  width: 100%;
}

.contact-info-card:hover i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.contact-info-card:hover h3 {
  color: var(--whiteColor);
}

.contact-info-card:hover p {
  color: var(--whiteColor);
}

.contact-info-card:hover p a {
  color: var(--whiteColor);
}

.contact-form .forgetTxt {
  color: #000000;
}
.contact-form .createAccount {
  color: #fc0606;
}
.contact-form.signup-form {
  /* max-width: 600px; */
  margin: auto;
}
.contact-form {
  margin-bottom: 30px;
  padding: 50px 35px 47px;
  background-color: #fff;
}

.contact-form .form-group {
  margin-bottom: 23px;
  position: relative;
}

.contact-form .form-group.checkbox-option {
  position: relative;
}

.contact-form .form-group.checkbox-option #chb2 {
  position: absolute;
  top: 6px;
  left: 0;
}

.contact-form .form-group.checkbox-option p {
  padding-left: 25px;
  font-size: 15px;
  color: var(--titleColor);
}

.contact-form .form-group.checkbox-option p a {
  color: var(--mainColor);
}

.contact-form .form-group.checkbox-option p a:hover {
  color: var(--titleColor);
}

.contact-form .form-group .form-control {
  /* height: 70px; */
  color: var(--bodyColor);
  border: none;
  background-color: #f6f6f6;
  padding: 15px 15px;
  width: 100%;
  border-radius: 15px;
}

.contact-form .form-group .form-control::-webkit-input-placeholder,
.contact-form .form-group .form-control:-ms-input-placeholder,
.contact-form .form-group .form-control::-ms-input-placeholder,
.contact-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.contact-form .form-group .form-control::-webkit-input-placeholder,
.contact-form .form-group .form-control:-ms-input-placeholder,
.contact-form .form-group .form-control::-ms-input-placeholder,
.contact-form .form-group .form-control::placeholder {
  color: var(--bodyColor);
}

.contact-form .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-form .form-group textarea.form-control {
  height: auto;
  border-radius: 0;
}

.contact-form .agree-label {
  margin-bottom: 15px;
  position: relative;
}

.contact-form .agree-label #chb1 {
  position: absolute;
  top: 7px;
  left: 0;
}

.contact-form .agree-label label {
  font-weight: 500;
  color: var(--titleColor);
  margin-left: 25px;
}

.contact-form .agree-label label a {
  color: var(--mainColor);
}

.contact-form .agree-label label a:hover {
  color: var(--titleColor);
}

.contact-form .agree-label label a.forget {
  position: absolute;
  right: 0;
}

.contact-form .with-errors {
  float: left;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #f00;
  font-weight: 400;
  display: block;
}

.contact-form .text-danger {
  font-size: 18px;
  margin-top: 15px;
}

.contact-form .default-btn {
  border: 0;
  outline: none;
}

.contact-map iframe {
  display: block;
  width: 100%;
  height: 460px;
}

.user-img {
  position: relative;
  margin-bottom: 30px;
}

.user-img img {
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
}

.user-all-form .contact-form {
  background-color: var(--whiteColor);
  padding: 45px 35px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.user-all-form .contact-form .user-title {
  font-size: 25px;
  padding: 0 0 15px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.user-all-form .contact-form .user-title::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 80px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.user-all-form .contact-form .form-group .form-control {
  background-color: #f5f5f5;
}

/*================================
Blog Area
===================================*/
.blog-card {
  position: relative;
  z-index: 1;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.blog-card a {
  display: block;
}

.blog-card .content {
  padding: 27px 25px 30px;
}

.blog-card .content ul {
  list-style-type: none;
  margin: 0 0 12px;
  padding: 0;
}

.blog-card .content ul li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li i {
  font-size: 18px;
  color: var(--mainColor);
  position: relative;
  top: 4px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li:hover {
  color: var(--mainColor);
}

.blog-card .content ul li:hover i {
  color: var(--titleColor);
}

.blog-card .content ul li:hover a {
  color: var(--mainColor);
}

.blog-card .content ul li a {
  display: inline-block;
  color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li::before {
  content: "";
  position: absolute;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 10px;
  right: -20px;
  width: 1px;
  height: 15px;
  background-color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li:last-child li {
  margin-right: 0;
}

.blog-card .content ul li:last-child::before {
  display: none;
}

.blog-card .content h3 {
  margin-bottom: 13px;
}

.blog-card .content h3 a {
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content p {
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content .read-btn {
  color: var(--titleColor);
  margin-bottom: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  font-weight: 600;
}

.blog-card .content .read-btn:hover {
  letter-spacing: 0.25px;
}

.blog-card:hover {
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.blog-card:hover .content h3 a {
  color: var(--mainColor);
}

.blog-card:hover .content .read-btn {
  color: var(--mainColor);
}

.blog-card-bg {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-card-bg .content {
  padding: 17px 0 0;
}

/*================================
Blog Details Area
===================================*/
.blog-details-content h1,
.blog-details-content h2,
.blog-details-content h3,
.blog-details-content h4,
.blog-details-content h5,
.blog-details-content h6 {
  margin-bottom: 15px;
}

.blog-details-content h1 {
  font-size: 32px;
}

.blog-details-content h2 {
  font-size: 29px;
}

.blog-details-content h3 {
  font-size: 26px;
}

.blog-details-content h4 {
  font-size: 23px;
}

.blog-details-content h5 {
  font-size: 20px;
}

.blog-details-content h6 {
  font-size: 17px;
}

.blog-details-content a {
  color: var(--goldenColor);
}

.blog-details-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--goldenColor);
}

.blog-details-content ul,
.blog-details-content ol {
  margin-bottom: 30px;
}

.blog-details-content ul li,
.blog-details-content ol li {
  margin-bottom: 10px;
}

.blog-details-content ul li:last-child,
.blog-details-content ol li:last-child {
  margin-bottom: 0;
}

.blog-details-content p {
  margin-bottom: 15px;
}

.blog-details-content .blog-preview-slider {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.blog-details-content .blog-preview-slider .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 47%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 45px;
  line-height: 53px !important;
  background-color: var(--whiteColor) !important;
  color: var(--bodyColor) !important;
  border-radius: 50%;
  text-align: center;
  font-size: 18px !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-prev:hover {
  color: var(--mainColor) !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 47%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 45px;
  height: 45px;
  line-height: 53px !important;
  background-color: var(--whiteColor) !important;
  color: var(--bodyColor) !important;
  border-radius: 50%;
  text-align: center;
  font-size: 18px !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-next:hover {
  color: var(--mainColor) !important;
}

.blog-details-content .blog-preview-img {
  margin-bottom: 25px;
  position: relative;
}

.blog-details-content .blog-preview-img .date {
  position: absolute;
  bottom: -12px;
  left: 25px;
  display: inline-block;
  text-align: center;
  padding: 8px 14px;
  font-size: 15px;
  background-color: var(--goldenColor);
  border-radius: 0;
  color: var(--whiteColor);
  font-weight: 600;
}

.blog-details-content .blog-preview-img-bg {
  /* background-image: url(../images/blog/blog-large-3.jpg); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 170px 0;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  overflow: hidden;
}

.blog-details-content .blog-preview-img-bg .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.blog-details-content .blog-preview-img-bg .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.blog-details-content .blog-preview-img-bg .play-btn i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-content .blog-preview-img-bg .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.blog-details-content .blog-preview-img-bg .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}

.blog-details-content .tag-list {
  list-style-type: none;
  margin: 0 0 5px;
  padding: 0;
}

.blog-details-content .tag-list li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li i {
  font-size: 18px;
  color: var(--mainColor);
  position: relative;
  top: 2px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li:hover {
  color: var(--mainColor);
}

.blog-details-content .tag-list li:hover i {
  color: var(--titleColor);
}

.blog-details-content .tag-list li:hover a {
  color: var(--mainColor);
}

.blog-details-content .tag-list li a {
  display: inline-block;
  color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li::before {
  content: "";
  position: absolute;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 10px;
  right: -20px;
  width: 1px;
  height: 15px;
  background-color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li:last-child li {
  margin-right: 0;
}

.blog-details-content .tag-list li:last-child::before {
  display: none;
}

.blog-details-content .blockquote {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: #f8f8f8;
  padding: 40px 30px 35px 60px;
  text-align: center;
}

.blog-details-content .blockquote i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  line-height: 1;
  font-size: 45px;
  color: var(--mainColor);
}

.blog-details-content .blockquote p {
  max-width: 620px;
  font-size: 18px;
  color: var(--titleColor);
  font-weight: 600;
  margin: 0 auto;
}

.blog-details-content .article-share {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  background: #f7f7f7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-details-content .article-share .article-tag ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blog-details-content .article-share .article-tag ul li {
  color: var(--titleColor);
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 0;
}

.blog-details-content .article-share .article-tag ul li.title {
  color: var(--mainColor);
  position: relative;
  top: 3px;
}

.blog-details-content .article-share .article-tag ul li a {
  border: none;
  color: var(--bodyColor);
}

.blog-details-content .article-share .article-tag ul li a:hover {
  color: var(--mainColor);
}

.blog-details-content .article-share .article-social-icon {
  float: right;
}

.blog-details-content .article-share .article-social-icon .social-icon {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.blog-details-content .article-share .article-social-icon .social-icon li {
  font-size: 18px;
  color: var(--bodyColor);
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

.blog-details-content
  .article-share
  .article-social-icon
  .social-icon
  li.title {
  font-weight: 500;
}

.blog-details-content
  .article-share
  .article-social-icon
  .social-icon
  li:last-child {
  margin-right: 0;
}

.blog-details-content .article-share .article-social-icon .social-icon li a i {
  color: var(--bodyColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  top: 2px;
}

.blog-details-content
  .article-share
  .article-social-icon
  .social-icon
  li
  a:hover {
  border: none;
}

.blog-details-content
  .article-share
  .article-social-icon
  .social-icon
  li
  a:hover
  i {
  color: var(--mainColor);
}

.blog-details-content .comments-form {
  margin-bottom: 30px;
}

.blog-details-content .comments-form .contact-form {
  max-width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-details-content .comments-form .contact-form h4 {
  margin-bottom: 15px;
}

.blog-details-content .comments-form .contact-form p {
  margin-bottom: 25px;
}

.side-bar-widget {
  margin-bottom: 35px;
  background-color: #f5f5f5;
  padding: 35px 23px;
}

.side-bar-widget .title {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 25px;
  border-bottom: 1px solid var(--mainColor);
  padding-bottom: 15px;
}

.side-bar-widget .title-tag {
  margin-bottom: 20px;
}

.side-bar-widget .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: none;
  width: 100%;
  background-color: var(--whiteColor);
}

.side-bar-widget .search-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 0;
  border: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border: 0;
  padding: 0 15px;
  color: var(--bodyColor);
}

.side-bar-widget .search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.side-bar-widget .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 50px;
  line-height: 53px;
  font-size: 22px;
  padding: 0 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.side-bar-widget .search-form button:hover {
  background: var(--titleColor);
}

.side-bar-widget .side-bar-categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.side-bar-widget .side-bar-categories ul li {
  position: relative;
  margin-bottom: 10px;
}

.side-bar-widget .side-bar-categories ul li:last-child {
  margin-bottom: 0;
}

.side-bar-widget .side-bar-categories ul li a {
  display: inline-block;
  color: var(--bodyColor);
  position: relative;
  z-index: 1;
  padding-left: 20px;
}

.side-bar-widget .side-bar-categories ul li a::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  background-color: var(--mainColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.side-bar-widget .side-bar-categories ul li:hover a {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.side-bar-widget .side-bar-categories ul li:hover a::before {
  background-color: var(--redColor);
}

.side-bar-widget .widget-popular-post {
  position: relative;
  overflow: hidden;
}

.side-bar-widget .widget-popular-post .item {
  overflow: hidden;
  margin-bottom: 12px;
}

.side-bar-widget .widget-popular-post .item:last-child {
  margin-bottom: 0;
}

.side-bar-widget .widget-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.side-bar-widget .widget-popular-post .item .thumb .full-image {
  width: 85px;
  height: 85px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: var(--whiteColor);
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg1 {
  /* background-image: url(../images/blog/blog-img1.jpg); */
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg2 {
  /* background-image: url(../images/blog/blog-img2.jpg); */
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg3 {
  /* background-image: url(../images/blog/blog-img3.jpg); */
}

.side-bar-widget .widget-popular-post .item .info {
  overflow: hidden;
  padding: 4px 0;
}

.side-bar-widget .widget-popular-post .item .info p {
  color: var(--bodyColor);
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: 600;
}

.side-bar-widget .widget-popular-post .item .info .title-text {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 600;
  max-width: 300px;
}

.side-bar-widget .widget-popular-post .item .info .title-text a {
  display: inline-block;
  color: var(--titleColor);
}

.side-bar-widget .widget-popular-post .item .info .title-text a:hover {
  color: var(--mainColor);
}

.side-bar-widget .side-bar-widget-tag {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-bar-widget .side-bar-widget-tag li {
  display: inline-block;
  margin: 5px;
}

.side-bar-widget .side-bar-widget-tag li a {
  padding: 8px 23px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  color: var(--bodyColor);
  font-size: 14px;
  background-color: var(--whiteColor);
  transition: 0.7s;
  font-weight: 500;
}

.side-bar-widget .side-bar-widget-tag li:hover a {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.author-profile {
  background-color: #f5f5f5;
  padding: 35px 30px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 35px;
}

.author-profile h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.author-profile img {
  border-radius: 50%;
  margin-bottom: 15px;
  max-width: 100px;
}

.author-profile p {
  margin-bottom: 17px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.author-profile .profile-social-link {
  list-style: none;
  margin: 0;
  padding: 0;
}

.author-profile .profile-social-link li {
  display: inline-block;
  margin-right: 5px;
}

.author-profile .profile-social-link li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  text-align: center;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
  display: inline-block;
}

.author-profile .profile-social-link li a:hover i {
  background-color: var(--titleColor);
}

.categories-title {
  margin-bottom: 30px;
}

.categories-title h3 {
  font-size: 26px;
  color: var(--titleColor);
}

.categories-title h3 span {
  color: var(--mainColor);
  font-size: 22px;
}

/*================================
Register Area
===================================*/
.register-area {
  position: relative;
  z-index: 1;
}

.register-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: #f5f5f5;
}

.register-form {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.register-form .top-header {
  background-color: var(--mainColor);
  padding: 40px 35px 37px;
  border-radius: 10px 10px 0 0;
}

.register-form .top-header h3 {
  color: var(--whiteColor);
  font-size: 35px;
  margin-bottom: 5px;
}

.register-form .top-header span {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.register-form .register-form-max {
  padding: 35px 35px 37px;
}

.register-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.register-form .form-group .form-control {
  height: 55px;
  color: var(--bodyColor);
  border: 1px solid #dddddd;
  background-color: var(--whiteColor);
  padding: 15px 25px;
  width: 100%;
  border-radius: 50px;
}

.register-form .form-group .form-control::-webkit-input-placeholder,
.register-form .form-group .form-control:-ms-input-placeholder,
.register-form .form-group .form-control::-ms-input-placeholder,
.register-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.register-form .form-group .form-control::-webkit-input-placeholder,
.register-form .form-group .form-control:-ms-input-placeholder,
.register-form .form-group .form-control::-ms-input-placeholder,
.register-form .form-group .form-control::placeholder {
  color: var(--bodyColor);
}

.register-form .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.register-form .default-btn {
  border: 0;
  outline: none;
  width: 100%;
}

.play-btn-area-two {
  /* background-image: url(../images/play-btn2.jpg); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 247px 0;
  text-align: center;
  border-radius: 10px;
}

.play-btn-area-two .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.play-btn-area-two .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 95px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.play-btn-area-two .play-btn i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.play-btn-area-two .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.play-btn-area-two .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}

.newsletter-area .newsletter-form {
  position: relative;
}

.newsletter-area .newsletter-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 50px;
  height: 70px;
  line-height: 70px;
  margin: 0;
  border: none;
  padding: 0 25px;
  color: var(--bodyColor);
}

.newsletter-area .newsletter-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--titleColor);
}

.newsletter-area .newsletter-form .subscribe-btn {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 50px;
  line-height: 52px;
  padding: 0 32px;
  border: 0;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
}

.newsletter-area .newsletter-form .subscribe-btn:hover {
  background-color: var(--titleColor);
}

.newsletter-area .newsletter-form .validation-danger {
  font-size: 18px;
  margin-top: 5px;
  color: red;
}

/*================================
Footer Area
===================================*/
.footer-contact-area {
  background-color: var(--mainColor);
}

.footer-contact-area .section-title h2 {
  color: var(--whiteColor);
}

.footer-contact-area .default-btn {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.footer-contact-area .default-btn:hover {
  color: var(--whiteColor);
}

.footer-area {
  /* background-color: var(--titleColor); */
  background-color: #000000;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget .footer-logo {
  margin-bottom: 20px;
}

.footer-widget h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--whiteColor);
  display: block;
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
}

.footer-widget h3::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 3px;
  background-color: var(--whiteColor);
  opacity: 0.2;
}

.footer-widget h3::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 3px;
  background-color: var(--mainColor);
}

.footer-widget p {
  margin-bottom: 25px;
  color: var(--whiteColor);
}

.footer-widget .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.footer-widget .social-link li.social-title {
  font-size: 18px;
  color: var(--whiteColor);
}

.footer-widget .social-link li:last-child {
  margin-right: 0;
}

.footer-widget .social-link li a {
  display: inline-block;
}

.footer-widget .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
}

.footer-widget .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.footer-widget .footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-list li {
  display: block;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.footer-widget .footer-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  background-color: var(--mainColor);
}

.footer-widget .footer-list li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-list li a {
  color: var(--whiteColor);
  cursor: pointer;
}

.footer-widget .footer-list li a:hover {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-list li:hover::before {
  color: var(--whiteColor);
}

.footer-widget .footer-list li:hover a {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-contact {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-contact li {
  display: block;
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}

.footer-widget .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact li i {
  font-size: 18px;
  color: var(--mainColor);
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 1;
}

.footer-widget .footer-contact li .content h4 {
  font-size: var(--fontSize);
  color: var(--whiteColor);
  margin-bottom: 5px;
}

.footer-widget .footer-contact li .content span {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.footer-widget .footer-contact li .content span a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-widget .footer-contact li:hover .content span a {
  color: var(--mainColor);
}

.copyright-area {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
}

.copy-right-text p {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.copy-right-text p b {
  color: var(--mainColor);
  font-weight: 500;
}

.copy-right-text p a {
  color: var(--mainColor);
  font-weight: 500;
}

.copy-right-text p a:hover {
  color: var(--whiteColor);
}

.single-content {
  margin-bottom: 30px;
}

.single-content h1,
.single-content h2,
.single-content h3,
.single-content h4,
.single-content h5,
.single-content h6 {
  margin-bottom: 15px;
}

.single-content h1 {
  font-size: 32px;
}

.single-content h2 {
  font-size: 29px;
}

.single-content h3 {
  font-size: 26px;
}

.single-content h4 {
  font-size: 23px;
}

.single-content h5 {
  font-size: 20px;
}

.single-content h6 {
  font-size: 17px;
}

.single-content a {
  color: var(--mainColor);
}

.single-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--mainColor);
}

.single-content ul,
.single-content ol {
  margin-bottom: 30px;
}

.single-content ul li,
.single-content ol li {
  margin-bottom: 10px;
}

.single-content ul li:last-child,
.single-content ol li:last-child {
  margin-bottom: 0;
}

.single-content p {
  margin-bottom: 15px;
}

.single-content p:last-child {
  margin-bottom: 0;
}

/*=================================
404 Error Area
===================================*/
.error-area .error-content {
  text-align: center;
  position: relative;
}

.error-area .error-content h1 {
  font-size: 260px;
  line-height: 0.8;
  font-weight: 700;
  color: var(--titleColor);
}

.error-area .error-content h1 span {
  color: var(--mainColor);
}

.error-area .error-content h3 {
  margin: 35px 0 0;
  position: relative;
  color: var(--titleColor);
  font-size: 35px;
}

.error-area .error-content p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

/*=================================
Coming Soon Area
===================================*/
.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  padding: 50px 30px;
  background-color: var(--titleColor);
  border: 3px solid var(--mainColor);
}

.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 50px;
}

.coming-soon-area .coming-soon-content p {
  font-size: 16px;
  max-width: 600px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  color: var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: var(--whiteColor);
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 10px;
  font-size: 28px;
  font-weight: 600;
  padding: 4px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -7px;
  font-size: 16px;
  font-weight: normal;
  color: var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer div:last-child {
  margin-right: 0;
}

.coming-soon-area .coming-soon-content #timer div:last-child::before {
  display: none;
}

.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}

.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}

.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  background-color: var(--whiteColor);
  padding-left: 15px;
  color: var(--whiteColor);
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 0;
  border: 1px solid #cccccc;
  color: #5d5d5d;
}

.coming-soon-area
  .coming-soon-content
  .newsletter-form
  .input-newsletter:focus {
  border-color: var(--mainColor);
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn::before {
  border-radius: 0;
  background-color: var(--titleColor);
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn:hover {
  color: #ffffff;
  background-color: #190f3c;
}

.coming-soon-area #validator-newsletter {
  text-align: left;
  color: #dc3545 !important;
}

/*=================================
Go Top Area
====================================*/
.go-top {
  position: fixed;
  top: 50%;
  right: 3%;
  opacity: 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background: var(--mainColor);
  border-radius: 0;
  width: 45px;
  height: 45px;
  line-height: 43px;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.go-top i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  vertical-align: middle;
}

.go-top:hover {
  background-color: var(--titleColor);
}

.go-top:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.go-top.active {
  top: 95%;
  -webkit-transform: translateY(-95%);
  transform: translateY(-95%);
  opacity: 1;
  visibility: visible;
}

/*================================
Pagination Area
===================================*/
.pagination-area {
  margin-top: 10px;
  margin-bottom: 30px;
}

.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--bodyColor);
  background-color: transparent;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 50px;
  border: 1px solid var(--mainColor);
}

.pagination-area .page-numbers:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.pagination-area .page-numbers i {
  position: relative;
  line-height: normal;
  top: 2px;
}

.pagination-area .page-numbers.current {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

/*=================================
Preloader CSS
====================================*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

#preloader-area {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
}

#preloader-area .spinner {
  position: absolute;
  z-index: 9999;
  width: 6px;
  height: 90px;
  margin-top: -45px;
  border-radius: 10px;
  background-color: var(--whiteColor);
  -webkit-animation: rotate40deg 0.8s infinite;
  animation: rotate40deg 0.8s infinite;
  animation-direction: alternate-reverse;
}

#preloader-area .spinner:nth-child(1) {
  margin-left: 0px;
}

#preloader-area .spinner:nth-child(2) {
  margin-left: -14px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

#preloader-area .spinner:nth-child(3) {
  margin-left: -28px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#preloader-area .spinner:nth-child(4) {
  margin-left: -42px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

#preloader-area .spinner:nth-child(5) {
  margin-left: -56px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#preloader-area .spinner:nth-child(6) {
  margin-left: -70px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#preloader-area .spinner:nth-child(7) {
  margin-left: -84px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

#preloader-area .spinner:nth-child(8) {
  margin-left: -98px;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

#preloader .preloader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background-color: var(--mainColor);
  z-index: 999;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

#preloader .preloader-section.preloader-left {
  left: 0;
}

#preloader .preloader-section.preloader-right {
  right: 0;
}

.loaded #preloader-area {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #preloader .preloader-section.preloader-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #preloader .preloader-section.preloader-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #preloader {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

@-webkit-keyframes rotate40deg {
  0% {
    height: 5px;
    margin-top: 0;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  100% {
    height: 90px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotate40deg {
  0% {
    height: 5px;
    margin-top: 0;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  100% {
    height: 90px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@-webkit-keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@-webkit-keyframes border-transform {
  0%,
  100% {
    border-radius: 60% 40% 56% 33% / 73% 82% 18% 27%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%,
  100% {
    border-radius: 60% 40% 56% 33% / 73% 82% 18% 27%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
/*# sourceMappingURL=style.css.map */

.fb-button {
  display: inline-block;
  padding: 5px 20px;
  border: none;
  background-color: #4267b2;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.fb-button i {
  margin-right: 10px;
}
.wa-button {
  display: inline-block;
  padding: 5px 20px;
  border: none;
  background-color: #00a884;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.wa-button i {
  margin-right: 10px;
}
.telegram-button {
  display: inline-block;
  padding: 5px 20px;
  border: none;
  background-color: #229ed9;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.discord-button {
  display: inline-block;
  padding: 5px 20px;
  border: none;
  background-color: #7289da;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.telegram-button i {
  margin-right: 10px;
}
.btn-groups-container {
  /*display: flex;*/
  flex-wrap: wrap;
  align-items: stretch;
}
.btn-groups-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

.btn-groups-col {
  flex-basis: 28%;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.btn-groups-col button {
  min-width: 185px;
  margin-right: 8px;
}
.telegram-button {
  background-image: url("../../images/telegram.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 19px;
  background-position-x: 26px;
}
.discord-button {
  background-image: url("../../images/discord.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 19px;
  background-position-x: 33px;
  padding-left: 34px;
}
.btn-facebook {
  display: inline-block;
  background-color: #3b5998;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
}
.btn-facebook:hover {
  color: #fff !important;
}

.default-btn {
  text-transform: uppercase;
  border: none;
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #ffffff;
  background-color: #cc0000;
  position: relative;
  border-radius: 3px;
  padding: 12.5px 30px 11px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.default-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.default-btn:hover::before {
  opacity: 0;
  visibility: hidden;
}

.btn-box {
  margin-top: 30px;
  position: relative;
}

.btn-box .default-btn:hover {
  /* background-color: #ffffff; */
  /* color: #000000; */
}

.btn-box ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: absolute;
  top: 5px;
  margin-bottom: 0;
  margin-left: 25px;
}

.btn-box ul li {
  display: inline-block;
  margin-right: 5px;
}

.btn-box ul li a {
  width: 35px;
  height: 35px;
  text-align: center;
  color: #cc0000;
  display: block;
  background-color: #ffffff;
  font-size: 17px;
  position: relative;
  border-radius: 3px;
}

.btn-box ul li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-box ul li a.facebook {
  background-color: #4267b2;
  color: #ffffff;
}

.btn-box ul li a.facebook:hover {
  background-color: #ffffff;
  color: #4267b2;
}

.btn-box ul li a.twitter {
  background-color: #1da1f2;
  color: #ffffff;
}

.btn-box ul li a.twitter:hover {
  background-color: #ffffff;
  color: #1da1f2;
}

.btn-box ul li a.instagram {
  background-color: #e1306c;
  color: #ffffff;
}

.btn-box ul li a.instagram:hover {
  background-color: #ffffff;
  color: #e1306c;
}

.btn-box ul li a.pinterest {
  background-color: #bd081c;
  color: #ffffff;
}

.btn-box ul li a.pinterest:hover {
  background-color: #ffffff;
  color: #bd081c;
}

.btn-box ul li a:hover {
  background-color: #cc0000;
  color: #ffffff;
}
.annualGroupModal .modal-dialog {
  max-width: 650px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.annualGroupModal .modal-dialog .modal-content {
  padding: 50px;
  border-radius: 0;
  border: none;
}

.annualGroupModal .modal-dialog .modal-content button.close {
  position: absolute;
  right: 1px;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #eeeeee;
  border-top: none;
  border-right: none;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #000000;
  margin: 0;
  text-shadow: unset;
  float: unset;
  line-height: 30px;
}

.annualGroupModal .modal-dialog .modal-content button.close:hover {
  background-color: red;
  border-color: red;
  color: #ffffff;
}

.annualGroupModal .modal-dialog .modal-content form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
}

.annualGroupModal .modal-dialog .modal-content form .help-block ul li {
  display: block;
  color: red;
  font-size: 16px;
}

.annualGroupModal .modal-dialog .modal-content form #msgSubmit {
  margin: 0;
}

.annualGroupModal .modal-dialog .modal-content form #msgSubmit.text-danger,
.annualGroupModal .modal-dialog .modal-content form #msgSubmit.text-success {
  margin-top: 10px;
}
.price-cut {
  position: relative;
}

.price-cut:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 2px solid;
  transform: translateY(-50%);
  color: red;
}
.offer-text span {
  display: inline !important;
}
.yearly-details {
  font-size: medium;
  font-weight: bold;
}
.copy_btn {
  line-height: 1;
}
.mat-dialog-container {
  padding: 5px !important;
}
.counter-txt {
  position: absolute;
  top: 20%;
}
.counter {
  font-size: 1.5rem;
  color: red;
  font-weight: bold;
}
.counter-txt h4 {
  font-size: 1.5rem;
}
.grp_btn {
  position: absolute;
  width: 35%;
  bottom: 70px;
  margin-left: 10px;
}
