.navbarNew {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
}
.navbarNew .navbar-brand {
  max-width: 165px;
}
.navbarNew .navbar-nav {
  background: #1b1b1b;
  padding: 7px;
  border-radius: 26px;
}
.navbarNew .navbar-nav .nav-item a {
  color: #fff;
  font-weight: 400;
}
.navbarNew .navbar-nav .nav-item a:hover {
  color: var(--themePurple);
}
.navbarNew .navbar-nav .nav-item .homeNav {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-left: 0;
  margin-right: 5px;
}
.navbarNew .regMain {
  text-transform: uppercase;
}
.navbarNew .regMain .loginBtn {
  border: 1px solid #8c8888;
  background: transparent;
  border-radius: 30px;
  padding: 11px 34px;
  margin-right: 10px;
  color: #000;
  transition: all 0.3s;
}
.navbarNew .regMain .loginBtn:hover {
  border: 1px solid var(--themePurple);
  background: var(--themePurple);
  color: #fff;
}
.navbarNew .regMain .SignUpBtn {
  border: 1px solid var(--themePurple);
  background: var(--themePurple);
  border-radius: 30px;
  padding: 11px 34px;
  margin-right: 10px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
}
.navbarNew .regMain .logoutDrop .SignUpBtn:hover {
  border-radius: 30px 30px 0px 0px;
}
.navbarNew .regMain .SignUpBtn:hover {
  border: 1px solid #8c8888;
  background: transparent;
  color: #000;
}
.homepageNew {
  font-family: just-sans, serif;
}
.homepageNew .homeSec1 {
  margin-bottom: 50px;
  color: #1b1b1b;
}
.homepageNew .homeSec1 .more {
  color: var(--themePurple);
}
.homepageNew .homeSec1 .content {
  margin-top: 50px;
}
.homepageNew .homeSec1 .tagline {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  font-size: 30px;
  font-weight: bold;
  /* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
}
.homepageNew .homeSec1 .heading span {
  color: var(--themePurple);
}
.homepageNew .homeSec1 .heading {
  font-family: just-sans-bold, serif;
  color: #1b1b1b;
  font-size: 72px;
  /* font-weight: bold; */
  line-height: 1;
  /* margin-top: 10px; */
  margin-bottom: 20px;
}
.homepageNew .homeSec1 .explore {
  border: 1px solid #8c8888;
  padding: 5px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  text-transform: uppercase;
}
.homepageNew .homeSec1 .explore .text {
  width: 100%;
  text-align: center;
}
.homepageNew .homeSec1 .explore .next {
  width: 40px;
  height: 40px;
  background: #636abc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.homepageNew .homeSec2 {
  padding: 60px 0;
}
.homepageNew .homeSec2 .sec2Ca {
  color: #fff;
  padding: 30px 20px;
  color: #fff;
  padding-top: 48px;
  height: 100%;
}
.homepageNew .homeSec2 .sec2Ca .ico {
  width: 56px;
  margin-bottom: 20px;
}
.homepageNew .homeSec2 .sec2Ca .title {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 1.2;
  margin-bottom: 8px;
}
.homepageNew .homeSec2 .sec2Ca .text {
  font-size: 12px;
}
.homepageNew .homeSec2 .sec2Ca.one {
  background: url(../../images/homepage/purpleFolder.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.homepageNew .homeSec2 .sec2Ca.two {
  background: url(../../images/homepage/beigeFolder.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: #3d3e41;
}
.homepageNew .homeSec2 .sec2Ca.three {
  background: url(../../images/homepage/orangeFolder.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.homepageNew .socialM {
  max-width: 160px;
  margin: auto;
}
.homepageNew .socialM .item {
  border: 1px solid #8c8888;
  padding: 11px;
  text-align: center;
  border-radius: 25px;
  margin-bottom: 10px;
}
.homepageNew .homeHeading span {
  color: var(--themePurple);
}
.homepageNew .homeHeading {
  font-family: just-sans-bold, serif;
  font-size: 50px;
  text-align: center;
  max-width: 525px;
  margin: auto;
  margin-bottom: 40px;
  line-height: 1;
  color: #1b1b1b;
  text-transform: capitalize;
  /* font-weight: bold; */
}
.homepageNew .homeSec3 {
  padding: 60px 0;
}
.homepageNew .homeSec3 .sec3Card {
  margin: auto;
  margin-bottom: 30px;
  max-width: 403px;
}
.homepageNew .homeSec3 .sec3Card .imgDiv {
  margin-bottom: 20px;
}
.homepageNew .homeSec3 .sec3Card .title {
  font-size: 24px;
  font-weight: 500;
  color: #202020;
}
.homepageNew .homeSec3 .sec3Card .desc {
  color: #6f6f6f;
}
.homepageNew .themeBlackBtn {
  padding: 10px 20px;
  min-width: 165px;
  text-align: center;
  border-radius: 30px;
  border: 2px solid #1b1b1b;
  background: #1b1b1b;
  color: #fff;
  transition: all 0.3s;
  text-transform: uppercase;
}
.homepageNew .themeBlackBtn:hover {
  background: #fff;
  color: #1b1b1b;
  border: 2px solid #fff;
}
.homepageNew .homeSec4 {
  position: relative;
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 50%, #b060ed 100%);
  border-radius: 18px;
  padding: 50px;
  margin: 50px 0;
  color: #fff;
}
.homepageNew .homeSec4 .homeHeading {
  color: #fff;
  margin-bottom: 20px;
}
.homepageNew .homeSec4 .imgDiv {
  position: relative;
  z-index: 1;
}
.homepageNew .homeSec4 .effect {
  width: 38%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.homepageNew .homeSec4 .title {
  font-size: 23px;
  font-weight: 500;
  line-height: 1.2;
}
.homepageNew .homeSec4 .desc {
  color: #dddddd;
  /* margin: 40px 0; */
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 580px;
}
.homepageNew .homeSec5 {
  padding: 60px 0;
}
.homepageNew .homeSec5 .autoplay-progress {
  display: none;
}
.homepageNew .homeSec5 .swiper + div {
  z-index: 1000;
}
.homepageNew .homeSec5 .imgDiv {
  position: relative;
  max-width: 416px;
  margin: auto;
}
.homepageNew .homeSec5 .imgDiv .playM {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  padding: 30px 20px;
}
.homepageNew .homeSec5 .imgDiv .playM .text {
  max-width: 210px;
  font-size: 18px;
}
.homepageNew .homeSec5 .imgDiv .playM .playIco {
  width: 58px;
  cursor: pointer;
}
.homepageNew .homeSec6 {
  background-color: #dbc8c8;
  border-radius: 16px;
  padding: 40px;
  margin: 50px 0;
}
.homepageNew .homeSec6 .reviewIco {
  width: 84px;
}
.homepageNew .homeSec6 .desc {
  font-size: 26px;
  color: #0b0a0a;
  margin: 30px 0;
}
.homepageNew .homeSec6 .nameM .name {
  background: #ff8d4f;
  display: inline-block;
  min-width: 230px;
  font-size: 23px;
  padding: 6px 10px;
  padding-left: 20px;
  border-radius: 25px 25px 25px 0;
  color: #000000;
  font-weight: 500;
}
.homepageNew .homeSec6 .nameM .desig {
  background: #ff8d4f;
  display: inline-block;
  min-width: 190px;
  font-size: 17px;
  padding: 6px 10px;
  padding-left: 20px;
  border-radius: 0 0 15px 15px;
  color: #000000;
}
.homepageNew .homeSec6 .imgdiv {
  width: 70%;
  margin: auto;
  position: relative;
}
.homepageNew .homeSec6 .imgdiv .icon {
  width: 85px;
}
.homepageNew .homeSec6 .imgdiv .icon.one {
  position: absolute;
  top: 8%;
  left: -2%;
}
.homepageNew .homeSec6 .imgdiv .icon.two {
  position: absolute;
  top: 50%;
  left: -23%;
  transform: translate(0, -50%);
}
.homepageNew .homeSec6 .imgdiv .icon.three {
  position: absolute;
  bottom: 8%;
  left: -2%;
}
.homepageNew .homeSec6 .imgdiv .icon.active {
  width: 100%;
}
.footer-new {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  color: #fff;
  padding-top: 60px;
}
.footer-new .footer-logo {
  max-width: 161px;
}
.footer-new .footer-logo img {
  filter: brightness(0) invert(1);
}
.footer-new .desc {
  color: #dfdfdf;
  margin: 30px 0;
  max-width: 330px;
}
.footer-new .social-link {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-new .social-link a {
  border: 1px solid #ffffff29;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: #fff;
}
.footer-new h3 {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}
.footer-new h4 {
  font-size: 16px;
  color: #fff;
}
.footer-new .footer-list {
  list-style: none;
  padding: 0;
}
.footer-new .footer-contact a {
  color: #fff;
}
.footer-new .footer-contact {
  list-style: none;
  padding: 0;
}
.footer-new li {
  padding: 5px 0;
}
.footer-new .footer-list li a {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
}
.footer-new a:hover {
  color: #c2c2c2 !important;
}
.footer-new .copyright-area {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.footer-new .copy-right-text p b {
  color: #fff;
}
.footer-new .termsFooter {
  color: #fff;
  padding: 15px 0;
}
.footer-new .termsFooter h6,
.footer-new .termsFooter p {
  color: #fff;
}
.footer-new .termsFooter .more {
  text-decoration: underline;
}
.modal-body {
  background: #fff;
  border-radius: 20px;
}
.modal-content {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  padding: 5px;
  border-radius: 22px;
  overflow: hidden;
}
.as-center {
  align-self: center;
}
.cursor-pointer {
  cursor: pointer;
}
.contact-widget-area {
  max-width: 1000px;
  margin: 50px auto;
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  padding: 4px;
  border-radius: 20px;
}
.contact-widget-area .contact-widget-area-inner {
  background-color: #fff;
  padding: 40px;
  border-radius: 18px;
}
.contact-widget-area .contact-widget-area-inner .contact-form {
  padding: 0 !important;
}
.themeBtn {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  border-radius: 30px;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  min-width: 228px;
  text-transform: uppercase;
}
.themeBtn:hover,
.themeBtn:focus,
.themeBtn:active {
  background: transparent;
  outline: 1px solid #140751;
  color: #140751;
}
.socialModal {
  max-width: 450px;
  margin: auto;
  padding: 20px 0;
}
.socialModal .socialL {
  /* background: #7ab4ff; */
  background: #2f8aff;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  height: 42.5px;
}
.socialModal .socialL:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}
.socialModal .socialL.tele {
  background: #816bdd;
}
.socialModal .socialL.discord {
  background: #2f8aff;
}
.socialModal .socialL.annual {
  background: #2f8aff;
}
.socialModal .socialL.whatsapp {
  background: #7ae36e;
}
.socialModal .socialL.youtube {
  background: #f34848;
}
.socialModal .socialL.tiktok {
  background: #000;
}
.socialModal .socialL i {
  width: 27px;
  margin-right: 10px;
  font-size: 18px;
}
.socialModal .socialL img {
  width: 27px;
  margin-right: 10px;
}
.socialModal .socialRow {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.socialModal .socialRow .ico {
  width: 41px;
  margin: 0 5px;
  transition: all 0.3s;
  display: inline-block;
}
.socialModal .socialRow .ico:hover {
  transform: scale(1.2);
  transition: all 0.3s;
}
.homepageNew .articleSec {
  padding: 60px 0;
}
.homepageNew .articleSec .imgDiv:hover {
  top: -10px;
  box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.75);
}
.homepageNew .articleSec .imgDiv {
  position: relative;
  top: 0px;
  max-width: 510px;
  width: 100%;
  margin: auto;
  text-decoration: none;
  border-radius: 18px;
  height: 100%;
}
.homepageNew .articleSec .imgDiv .layout {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 17.5%, #000000 78%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  border-radius: 16px;
}
.homepageNew .articleSec .imgDiv img {
  width: 100%;
  border-radius: 16px;
}
.homepageNew .articleSec .imgDiv .content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 25px;
  color: #fff;
}
.homepageNew .articleSec .imgDiv .content .date {
  font-size: 14px;
  margin-bottom: 15px;
}
.homepageNew .articleSec .imgDiv .content .title {
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.3;
}
.homepageNew .articleSec .imgDiv .content .learnMore {
  color: #fff;
  margin-top: 15px;
}
.homepageNew .articleSec .imgDiv .content .learnMore:hover {
  text-decoration: underline;
}
.navbar.portfolioNav .themeBtn {
  min-width: 150px;
  color: #fff;
}
.navbar.portfolioNav .themeBtn:hover {
  color: #000;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.slick-prev:before,
.slick-next:before {
  display: none !important;
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .homepageNew .homeSec4 .title {
    font-size: 23px;
  }
  .homepageNew .homeSec6 .imgdiv .icon.two {
    left: -26%;
  }
  .homepageNew .homeSec1 .heading {
    font-size: 70px;
  }
}
@media screen and (max-width: 1200px) {
  .navbarNew .navbar-nav .nav-item {
    padding: 3px 0;
  }
  .navbarNew .regMain {
    margin-top: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .homepageNew .socialM {
    margin-bottom: 20px;
  }
  .homepageNew .homeSec4 {
    background: linear-gradient(0deg, #384ef4 0%, #b060ed 39%, #b060ed 100%);
  }
  .homepageNew .homeSec4 .imgDiv {
    margin-top: 40px;
  }
  .homepageNew .articleSec .imgDiv {
    /* margin-bottom: 20px; */
    max-width: 450px;
  }
  .homepageNew .articleSec .imgDiv .content .title {
    font-size: 19px;
  }
  .homepageNew .articleSec .imgDiv .content .desc {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .homepageNew .homeSec2 .sec2Ca {
    margin-bottom: 30px;
  }
  .homepageNew .homeSec5 .imgDiv {
    margin-bottom: 20px;
  }
  .drivers-row .themeBtn {
    font-size: 16px;
    padding: 10px 20px;
    min-width: auto;
  }
}
@media screen and (max-width: 576px) {
  .homepageNew .homeSec1 .tagline {
    font-size: 25px;
  }
  .homepageNew .homeSec1 .heading {
    font-size: 50px;
  }
  .homepageNew .homeHeading {
    font-size: 33px;
    word-break: break-word;
  }
  .homepageNew .homeSec4 {
    padding: 25px 15px;
  }
  .homepageNew .homeSec4 .title {
    font-size: 19px;
  }
  .themeBtn {
    font-size: 16px;
    padding: 10px 20px;
    min-width: auto;
  }
  .broadcastModal .broadBtnMain .borderBtn {
    font-size: 17px;
  }
  .contact-widget-area {
    margin-left: 12px;
    margin-right: 12px;
  }
  .contact-widget-area .contact-widget-area-inner {
    padding: 20px 12px;
  }
}
